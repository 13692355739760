.my-32 {
    margin-top: 2rem;
    margin-bottom: 8rem;
}

.male_h1 {
    background-color: #bc00a3;
    /* color: transparent; */
    background-image: linear-gradient(to right, #e50ac8, #ff48e7 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding-top: 20px;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
    font-size: 46px;
    position: relative;
  }
  
  .male_h1::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -10px; /* Adjust the position of the underline */
    left: 0;
    width: 15%;
    height: 3px; /* Adjust the height of the underline */
    background-image:  linear-gradient(to right, #341E93, #E73F77);
    /* background-image: linear-gradient(to right, #ff62ea, #fb67e8, #ff92fb);  */
    transition: width 0.5s ease-in-out; /* Smooth transition for width change */
  }
  
  .male_h1:hover::after {
    width: 25%; /* Change the width on hover */
  }

  .who_font_style {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  }

  .male_con_sub_heading {
    color: #000000;
    /* color: transparent; */
    /* background-image: linear-gradient(to right, #e50ac8, #ff48e7 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    margin: 0;
    padding-top: 20px;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
    font-size: 36px;
    position: relative;
  }
  
  .male_con_sub_heading::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -10px; /* Adjust the position of the underline */
    left: 0px;
    width: 15%;
    height: 3px; /* Adjust the height of the underline */
    background-image: linear-gradient(to right, #341E93, #E73F77);
    /* background-image: linear-gradient(to right, #ff62ea, #fb67e8, #ff92fb);  */
    transition: width 0.5s ease-in-out; /* Smooth transition for width change */
  }

  .male_con_sub_heading_1 {
    background-color: #11cbff;
    /* color: transparent; */
    background-image:linear-gradient(to right, #341E93, #E73F77);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    margin: 0;
    padding-top: 20px;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
    font-size: 36px;
    position: relative;
  }
