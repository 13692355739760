/* .re_hero_h1 {
    background-color: #bc00a3;
    background-image: linear-gradient(to right, #e50ac8, #ff66eb 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
} */

.male_hero_p_1 {
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
}

.male_hero_h1 {
    background-color: #bc00a3;
    /* color: transparent; */
    background-image: linear-gradient(to right, #e50ac8, #ff48e7 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding-top: 20px;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
    font-size: 46px;
    position: relative;
  }
  
  .male_hero_h1::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -10px; /* Adjust the position of the underline */
    left: 0;
    width: 30%;
    height: 3px; /* Adjust the height of the underline */
    background-image:  linear-gradient(to right, #341E93, #E73F77);
    /* background-image: linear-gradient(to right, #ff62ea, #fb67e8, #ff92fb);  */
    transition: width 0.5s ease-in-out; /* Smooth transition for width change */
  }

  .male_hero_h1:hover::after {
    width: 50%; /* Change the width on hover */
  }

  .who_font_style {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  }