.a_hero_h1 {
    background-color: #bc00a3;
    /* color: transparent; */
    background-image: linear-gradient(to right, #e50ac8, #ff66eb 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
}

.a_hero_p_1 {
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
}

/* background-color: #0071bc;
background-image: linear-gradient(to right, #006fbe, #00a4e3 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0; */