.css-h93ljk-MuiTypography-root {
  font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
  margin-bottom: 1.35em;
}
.css-r40f8v-MuiTypography-root {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 0.885rem;
}

.blog_sub_heading_2 {
  color: #000000;
  /* color: transparent; */
  /* background-image: linear-gradient(to right, #e50ac8, #ff48e7 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin: 0;
  padding-top: 20px;
  font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
  font-size: 36px;
  position: relative;
}

.blog_sub_heading {
  color: #000000;
  /* color: transparent; */
  /* background-image: linear-gradient(to right, #e50ac8, #ff48e7 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin: 0;
  padding-top: 20px;
  font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
  font-size: 36px;
  position: relative;
}

.blog_sub_heading::after {
  content: "";
  position: absolute;
  display: block;
  bottom: -10px; /* Adjust the position of the underline */
  left: 0px;
  width: 15%;
  height: 3px; /* Adjust the height of the underline */
  background-image:linear-gradient(to right, #341E93, #E73F77);
  /* background-image: linear-gradient(to right, #ff62ea, #fb67e8, #ff92fb);  */
  transition: width 0.5s ease-in-out; /* Smooth transition for width change */
}


/* Basic button styling */
.glowing-button {
  text-align: center;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: pink;
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/* Glowing animation effect */
.glowing-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.6s ease;
  border-radius: 50%;
  z-index: 0;
  transform: translate(-50%, -50%) scale(0);
}

.glowing-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.filter-box {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.filter-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.filter-item {
  padding: 8px 12px;
  margin: 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-item:hover {
  background-color: pink;
}
  /* BlogCards.css */

