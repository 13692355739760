.my-32 {
    margin-top: 2rem;
    margin-bottom: 8rem;
}

.female_h1 {
    background-color: #bc00a3;
    /* color: transparent; */
    background-image: linear-gradient(to right, #e50ac8, #ff48e7 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding-top: 20px;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
    font-size: 46px;
    position: relative;
  }
  
  .female_h1::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -10px; /* Adjust the position of the underline */
    left: 0;
    width: 15%;
    height: 3px; /* Adjust the height of the underline */
    background-image: linear-gradient(to right, #341E93, #E73F77);
    /* background-image: linear-gradient(to right, #ff62ea, #fb67e8, #ff92fb);  */
    transition: width 0.5s ease-in-out; /* Smooth transition for width change */
  }
  
  .female_h1:hover::after {
    width: 25%; /* Change the width on hover */
  }

  .who_font_style {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  }

  .female_sub_heading {
    color: #000000;
    /* color: transparent; */
    /* background-image: linear-gradient(to right, #e50ac8, #ff48e7 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    margin: 0;
    padding-top: 20px;
    font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
    font-size: 36px;
    position: relative;
  }
  
  .female_sub_heading::after {
    content: "";
    position: absolute;
    text-align: center;
    display: block;
    bottom: -10px; /* Adjust the position of the underline */
    left: 50px;
    width: 15%;
    height: 3px; /* Adjust the height of the underline */
    background-image: linear-gradient(to right, #341E93, #E73F77);
    /* background-image: linear-gradient(to right, #ff62ea, #fb67e8, #ff92fb);  */
    transition: width 0.5s ease-in-out; /* Smooth transition for width change */
  }

  /* Define hover style for the heading */
.ovulation_heading {
  color: #341E93;
  transition: color 0.3s ease-in-out; /* Smooth color transition */
}

.ovulation_heading:hover {
  color: #E73F77; /* Change color on hover */
  cursor: pointer; /* Show cursor as pointer on hover */
}

/* Define transition for showing/hiding content */
.ovulation_content {
  opacity: 1; /* Initially hide content */
  max-height: 2; /* Initially collapse content */
  /* overflow: hidden; */
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition */
}

.show-ovulation-content {
  opacity: 1; /* Show content */
  max-height: 500px; /* Adjust height based on content */
}

/* Define the shaking animation */
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px) rotate(-5deg); }
  50% { transform: translateX(5px) rotate(5deg); }
  75% { transform: translateX(-5px) rotate(-5deg); }
  100% { transform: translateX(0); }
}

/* Apply the animation to the icon */
.shake {
  animation: shake 0.5s infinite; /* Change the time and iteration as needed */
}

  